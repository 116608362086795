.content-body main {
  h2 {
    @apply text-2xl py-4;
    &:first-child {
      @apply pt-0;
    }
  }

  h3 {
    @apply text-xl py-4;
    &:first-child {
      @apply pt-0;
    }
  }

  h4 {
    @apply text-base py-4;
    &:first-child {
      @apply pt-0;
    }
  }

  h5 {
    @apply text-base py-4;
    &:first-child {
      @apply pt-0;
    }
  }

  h6 {
    @apply text-base py-4;
    &:first-child {
      @apply pt-0;
    }
  }

  p {
    @apply text-base py-4;
  }

  a {
    @apply underline;
  }

}
