nav {
  .side-nav {
    @apply flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-900 rounded-md transition ease-in-out duration-150;
    &.active {
      @apply bg-gray-100;
    }

    &.inactive {
      @apply text-gray-300;
      &:hover {
        @apply text-gray-400;
      }
    }

    &:hover {
      @apply text-gray-900 bg-gray-100;
    }

    &:focus {
      @apply outline-none bg-gray-200;
    }

    &:not(:first-child) {
      @apply mt-1;
    }

    svg {
      @apply flex-shrink-0 -ml-1 mr-3 h-6 w-6 text-gray-400 transition ease-in-out duration-150;
      &:group-hover, &:group-focus {
        @apply text-gray-500;
      }
    }
  }
}
