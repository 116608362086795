@tailwind base;
@tailwind components;
@tailwind utilities;

@import "default-styles";
@import "dropzone";
@import "flash";
@import "form-default";
@import "main-cta";
@import "nav-side";
@import "nav-top";
@import "who-can-register";
@import "aboutpage";

h2 {
  &.main-h2 {
    @apply m-4 pt-16 max-w-3xl mx-auto text-center text-3xl leading-7 text-gray-800;
  }
}

.centered-slogan {
  @apply relative;
  h2, p {
    @apply pt-0;
  }
}

p {

  a {
    @apply underline;
  }

  &.main-sub {
    @apply m-4 max-w-3xl mx-auto text-center text-2xl leading-7 text-gray-500;
  }

  &.main-sub {
    @apply pb-16;
  }
}

.simple-three-column {

  @apply max-w-xl mx-auto px-4;

  @screen sm {
    @apply px-6;
  }

  @screen lg {
    @apply max-w-screen-xl px-8;
  }

  .simple-three-column-inner {

    @screen lg {

      @apply grid grid-cols-3 gap-8;

      > div:not(:first-child) {

        @apply mt-10;

        @screen lg {

          @apply mt-0;

        }
      }
    }
  }
}

.card-icon {
  @apply flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white;
}

.card-content {
  h6, h5, h4, h3, h2 {
    @apply text-lg leading-6 font-medium text-gray-900;
  }

  p {
    @apply mt-2 text-base leading-6 text-gray-500;
  }
}

.section-heading {
  @apply relative;
  h3 {
    @apply text-center text-3xl leading-7 font-extrabold tracking-tight text-gray-800;
    @screen sm {
      @apply text-4xl leading-10;
    }
  }
}

.content-wrapper {
  @apply max-w-6xl;
}
.application-wrapper {
  @apply max-w-5xl;
  &.narrow {
    @apply max-w-lg;
  }
}

.application-wrapper, .content-wrapper {

  @apply mx-auto pb-12 px-4;
  @screen sm {
    @apply px-6;
  }
  @screen lg {
    @apply px-8;
  }

  .application-outer, .content-outer {

    @apply bg-white rounded-lg shadow px-5 py-6;

    @screen sm {
      @apply px-6;
    }

    .application-inner, .content-inner {
      @apply mt-10;
      @screen sm {
        @apply mt-0;
      }
    }

  }

}

.side-by-side {

  @apply relative mt-12;
  @screen lg {
    @apply mt-24 grid grid-cols-2 gap-8 items-center;
  }

  .side-by-side-heading {
    @apply relative;
    h2, h3, h4, h5 {
      @apply text-2xl leading-8 font-extrabold text-gray-800 tracking-tight;
      @screen sm {
        @apply text-3xl leading-9;
      }
    }

    p {
      @apply mt-3 text-lg leading-7 text-gray-500;
    }
  }

  ul {
    @apply mt-10;
    li:not(:first-child) {
      @apply mt-10;
    }

    li > div {
      @apply flex;
      .icon-container {
        @apply flex-shrink-0;
        .icon-inner {
          @apply flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white;
        }
      }

      .text-container {
        @apply ml-4;
        h4, h5 {
          @apply text-lg leading-6 font-medium text-gray-900;
        }

        p {
          @apply mt-2 text-base leading-6 text-gray-500;
        }
      }
    }
  }

}