.who-can-register {
  @apply relative bg-gray-100 pt-10 pb-8 px-4;

  @screen sm {
    @apply px-6;
  }
  @screen lg {
    @apply pt-24 pb-28 px-8;
  }
}

.who-can-register, .main-statement {

  p.main-sub {
    @apply pb-0;
  }

  .box {
    @apply relative max-w-7xl mx-auto;

    .heading {
      @apply text-center;
      h2, h3, h4 {
        @apply text-3xl leading-9 tracking-tight font-extrabold text-gray-900;
        @screen sm {
          @apply text-4xl leading-10;
        }
      }

      p {
        @apply mt-3 max-w-2xl mx-auto text-xl leading-7 text-gray-500;
        @screen sm {
          @apply mt-4;
        }
      }
    }

    .content {
      @apply mt-12 grid gap-5 max-w-lg mx-auto;
      @screen lg {
        @apply grid-cols-3 max-w-none;
      }

      .content-box {
        @apply flex flex-col rounded-lg shadow-lg overflow-hidden;
        .content-image {
          @apply flex-shrink-0;
        }

        .content-outer {
          @apply flex-1 bg-white p-6 flex flex-col justify-between;
          h3 {
            @apply mt-2 text-xl leading-7 font-semibold text-gray-900;
          }

          p {
            @apply mt-3 text-base leading-6 text-gray-500;
          }
        }
      }

    }
  }


}