
/** TOP MENU **/
.default-body {
  .nav-wrapper {
    @apply max-w-screen-xl;
    @screen md {
      @apply mx-auto;
    }

    nav.main-nav {
      @apply relative flex items-center justify-between;
      @screen sm {
        @apply h-10;
      }
      @screen md {
        @apply justify-center;
      }

      .top-menu {
        @apply hidden;
        @screen md {
          @apply block;
        }
        .menu-item {
          &:not(.black) {
            @apply text-white;
            &:hover {
              @apply text-gray-200;
            }
          }
          &.black {
            @apply text-black;
            &:hover {
              @apply text-gray-800;
            }
          }

          @apply font-medium transition duration-150 ease-in-out;

          &:not(:first-child) {
            @apply ml-10;
          }
        }
      }
    }

    .burger button {
      @apply bg-white;
    }
  }
}

.detail-body, .content-body {
  .main-nav {
    @apply bg-gray-800;
  }

  .top-menu {
    @apply ml-10 flex items-baseline;

    .menu-item {
      @apply px-3 py-2 rounded-md text-sm font-medium;
      &:hover, &:focus {
        @apply text-white bg-gray-700;
      }

      &:focus {
        @apply outline-none;
      }

      &:not(.mobile-menu) {
        &:not(:first-child) {
          @apply ml-4;
        }
      }

      &:not(.active) {
        @apply text-gray-300;
      }

      &.active {
        @apply text-white bg-gray-900;
      }

      &.mobile-menu {
        @apply block px-3 py-2 rounded-md text-base font-medium text-gray-700 transition duration-150 ease-in-out;
        &:not(:first-child) {
          @apply mt-1;
        }
        &:hover {
          @apply text-gray-900 bg-gray-50;
        }
        &:focus {
          @apply outline-none text-gray-900 bg-gray-50;
        }
      }
    }
  }
}

.burger {
  @apply -mr-2 flex;
  @screen md {
    @apply hidden;
  }
  button {
    @apply inline-flex items-center justify-center p-2 rounded-md text-gray-400;
    &:hover {
      @apply text-white bg-gray-700;
    }

    &:focus {
      @apply outline-none bg-gray-700 text-white;
    }
  }
}

.mobile-menu-box {

  @apply hidden;

  &.open {
    @apply absolute w-full;
  }
  @screen md {
    @apply hidden;
  }
}

.login-button {
  @apply inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-600 bg-white transition duration-150 ease-in-out cursor-pointer;
  &:hover {
    @apply text-indigo-500;
  }
  &:focus {
    @apply outline-none border-indigo-300 shadow-outline-indigo;
  }
  &:active {
    @apply bg-gray-50 text-indigo-700;
  }
}