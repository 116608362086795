.iconbox-outer {
  @apply text-center rounded overflow-hidden shadow-lg bg-indigo-500 mx-6 mb-6;

  @screen sm {
    @apply mb-0;
  }
  @screen md {
    @apply max-w-sm mx-20;
  }

  @screen lg {
    @apply max-w-xs mx-0;
  }

  p {
    @apply text-white text-base pt-5;
  }

}

.about-who-what-why {
  h3, h4, h5 {
    @apply text-lg leading-6 font-medium text-gray-900;
  }

  p {
    @apply mt-2 text-base leading-6 text-gray-500;
  }
}