.dropzone-outer {
  @apply flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md;
  .vue-dropzone {
    .dz-preview {
      @apply grid grid-cols-4;
      .dz-image, .dz-details, .dz-progress, .dz-error-message {
        @apply col-span-4;
      }

      .dz-details {
        @apply text-xs;
        .dz-size {
          @apply inline pr-1;
        }

        .dz-filename {
          @apply inline;
        }
      }

      .dz-progress {
        @apply w-full h-3;
        .dz-upload {
          @apply bg-teal-600 text-xs leading-none py-1 h-3 text-center text-white block;
        }
      }

      .dz-error-message {
        > span {
          @apply flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1;
        }
      }

      .dz-success-mark, .dz-error-mark {
        @apply col-span-2 mt-4;
      }

      .dz-error-mark svg {
        @apply float-right;
      }
    }
  }
}