.main-cta {
  @apply max-w-screen-xl mx-auto text-center py-12 px-4;
  @screen sm {
    @apply px-6;
  }
  @screen lg {
    @apply py-16 px-8;
  }

  h2 {
    @apply text-3xl leading-9 font-extrabold tracking-tight text-white;
    @screen sm {
      @apply text-4xl leading-10;
    }
  }

}