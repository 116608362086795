div.flash {
  @apply border-t-4 rounded-b px-4 py-3 shadow-md;

  svg {
    @apply fill-current h-6 w-6 mr-4;
  }

  &.success {
    @apply bg-teal-100 border-teal-500 text-teal-900;
    svg {
      @apply text-teal-500;
    }
  }

  &.warning {
    @apply bg-orange-100 border-orange-500 text-orange-900;
    svg {
      @apply text-orange-500;
    }
  }

  &.error {
    @apply bg-red-100 border-red-500 text-red-900;
    svg {
      @apply text-red-500;
    }
  }

  &.info {
    @apply bg-blue-100 border-blue-500 text-blue-900;
    svg {
      @apply text-blue-500;
    }
  }

}