.default-form {

  .form-grid {
    @apply grid grid-cols-6 gap-6;
  }

  .form-group-label {
    @apply block text-sm leading-5 font-bold text-gray-700;
  }

  .form-control {
    label, .label {
      @apply block text-sm font-medium leading-5 text-gray-700;
      &.validation {
        @apply italic text-red-400 transition ease-in duration-300;
      }
    }


    .form-input {
      @apply mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm  transition duration-150 ease-in-out;
      &:focus {
        @apply outline-none shadow-outline-blue border-blue-300;
      }

      @screen sm {
        @apply text-sm leading-5;
      }
    }

    .form-radio,
    .form-checkbox {
      @apply h-4 w-4 text-indigo-600 transition duration-150 ease-in-out;
    }

    &.checkbox {

      @apply mt-4;
      @screen sm {
        @apply mt-0;
      }

      .label-and-description {
        @apply pl-7 text-sm leading-5;
        label {
          @apply font-medium text-gray-700;
        }

        p {
          @apply text-gray-500;
        }
      }
    }

  }

  .form-submit, .form-select-button {
    @apply py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 shadow-sm transition duration-150 ease-in-out;
    &:hover {
      @apply bg-indigo-500;
    }

    &:focus {
      @apply outline-none shadow-outline-blue;
    }

    &:active {
      @apply bg-indigo-600;
    }
  }
}
.formApplicationDescriptive {
  @apply px-4 pt-6;

  @screen sm {
    @apply px-0;
  }

  h2, h3, h4 {
    @apply text-sm font-medium leading-6 text-gray-900;

    :not(:first-child) {
      @apply pt-2;
    }

    + p {
      @apply pt-1;
    }
  }

  p {
    @apply text-xs leading-5 text-gray-600;
    + p {
      @apply pt-2;
    }
  }
}
